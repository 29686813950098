@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



p, div {
    font-family: Lato;
  }
  
  /* 
    Colors:
    https://coolors.co/22223b-4a4e69-eadae4-9a8c98-c9ada7 
  */
  /*
  YANKEES BLUE: #22223B
  INDEPENDENCE: #4A4E69
  PLATINUM: #EADAE4
  SPANISH GRAY: #9A8C98
  SILVER PINK: #C9ADA7
  */
  
  .wrapper-login {
    height: 97vh;
    width: 100%;
    margin-top:20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4A4E69;
  }
  
  .form-wrapper-login {
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 80%;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }

  .form-wrapper-get-code {
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 80%;
    min-width: 100px;
    min-height: 200px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }

  .form-wrapper-get-code > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.2em;
    font-weight: darker;
    margin-top: 0.25em;
    color: #222;
    text-align: center;
  }

  .form-wrapper-new-password {
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 80%;
    min-width: 100px;
    min-height: 200px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }

  .form-wrapper-new-password > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.5em;
    font-weight: darker;
    margin-top: 0.25em;
    color: #222;
    text-align: center;
  }
  
  .form-wrapper-login > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #222;
    text-align: center;
  }
  
  .login-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  label {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }
  
  input::-webkit-input-placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  input:-ms-input-placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  input::-ms-input-placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }

  input.error {
    border: 1px solid #EADAE4;
  }
  
  .sign-in-login {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding: 	0.938em;
    border: none;
    border-radius: 4px;
    background-color: #22223B;
    color: #fefefe;
  }
  
  .sign-in-login:hover {
    background-color: #4A4E69;
    color: #fefefe;
  }
  
  .error {
    color:#db2269;
    font-size: 0.625em;
    display: relative;
  }
  
  .email-login,
  .password-login {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }

  .password-login {
    margin-bottom: 2px;
  }
  
  .submit-login {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
p, div {
  font-family: Lato;
}

/* 
  Colors:
  https://coolors.co/22223b-4a4e69-eadae4-9a8c98-c9ada7 
*/
/*
YANKEES BLUE: #22223B
INDEPENDENCE: #4A4E69
PLATINUM: #EADAE4
SPANISH GRAY: #9A8C98
SILVER PINK: #C9ADA7
*/

.wrapper {
  height: 100%;
  width: 100%;
  margin-top:20px;
  margin-bottom: 20px;
  padding:0;
  /* padding: 0;
  margin: 0;
  top:0;
  left:0;
  position: fixed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4A4E69;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 750px;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
}

/* .login-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 450px;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
} */

.form-wrapper > h2 {
  display: flex; 
   justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #222;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

label {
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
}

input {
  padding: 1px 1px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

select {
  padding: 3px 3px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

input::-webkit-input-placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

input:-ms-input-placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

input::-ms-input-placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

.button {
  min-width: 50%;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 	0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223B;
  color: #fefefe;
  transform: translateX(50%);
}

button:hover {
  background-color: #4A4E69;
  color: #fefefe;
}

input.error {
  border: 1px solid #EADAE4;
}

.error {
  color:#db2269;
  font-size: 0.625em;
  display: relative;
}

.first_name,
.last_name,
.email,
.phone_number,
.number_masks,
.type_masks,
.preferred_exchange,
.additional_information,
.password,
.confirm_password,
.account,
.location {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.submit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.left-column,
.right-column {
  flex: 50% 1;
  padding: 0 10px;
}

/* .left-column {
  position: relative; 
  bottom: 123px;
} */

.red {
  color: red;
}




/* .previewComponent {
  position: absolute; 
  bottom: 30px;
} */

.label {
  cursor: pointer;
}

#fileInput
{   
  /* padding: 10px;
  margin: 15px; */
  cursor: pointer;
  opacity: 0;
  position: absolute; 
  z-index: -1;
}

.imgPreview {
  text-align: center;
  /* margin: 5px 15px; */
  height: 100px;
  width: 100px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-radius: 50%;
}

.profile_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.previewText {
  width: 100%;
  margin-top: 30px;
}

/* .submitButton {
  background: black;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
} */


/* .submitButton:hover {
    background: #efefef
} */

/* .centerText {
  text-align: center;
  width: 500px;
} */


