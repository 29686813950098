p, div {
    font-family: Lato;
  }
  
  /* 
    Colors:
    https://coolors.co/22223b-4a4e69-eadae4-9a8c98-c9ada7 
  */
  /*
  YANKEES BLUE: #22223B
  INDEPENDENCE: #4A4E69
  PLATINUM: #EADAE4
  SPANISH GRAY: #9A8C98
  SILVER PINK: #C9ADA7
  */
  
  .wrapper-login {
    height: 97vh;
    width: 100%;
    margin-top:20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4A4E69;
  }
  
  .form-wrapper-login {
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 80%;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }

  .form-wrapper-get-code {
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 80%;
    min-width: 100px;
    min-height: 200px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }

  .form-wrapper-get-code > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.2em;
    font-weight: darker;
    margin-top: 0.25em;
    color: #222;
    text-align: center;
  }

  .form-wrapper-new-password {
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 80%;
    min-width: 100px;
    min-height: 200px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }

  .form-wrapper-new-password > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.5em;
    font-weight: darker;
    margin-top: 0.25em;
    color: #222;
    text-align: center;
  }
  
  .form-wrapper-login > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #222;
    text-align: center;
  }
  
  .login-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  label {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }
  
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }

  input.error {
    border: 1px solid #EADAE4;
  }
  
  .sign-in-login {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding: 	0.938em;
    border: none;
    border-radius: 4px;
    background-color: #22223B;
    color: #fefefe;
  }
  
  .sign-in-login:hover {
    background-color: #4A4E69;
    color: #fefefe;
  }
  
  .error {
    color:#db2269;
    font-size: 0.625em;
    display: relative;
  }
  
  .email-login,
  .password-login {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }

  .password-login {
    margin-bottom: 2px;
  }
  
  .submit-login {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }