p, div {
  font-family: Lato;
}

/* 
  Colors:
  https://coolors.co/22223b-4a4e69-eadae4-9a8c98-c9ada7 
*/
/*
YANKEES BLUE: #22223B
INDEPENDENCE: #4A4E69
PLATINUM: #EADAE4
SPANISH GRAY: #9A8C98
SILVER PINK: #C9ADA7
*/

.wrapper {
  height: 100%;
  width: 100%;
  margin-top:20px;
  margin-bottom: 20px;
  padding:0;
  /* padding: 0;
  margin: 0;
  top:0;
  left:0;
  position: fixed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4A4E69;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 750px;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
}

/* .login-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 450px;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
} */

.form-wrapper > h2 {
  display: flex; 
   justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #222;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

label {
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
}

input {
  padding: 1px 1px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

select {
  padding: 3px 3px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

.button {
  min-width: 50%;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 	0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223B;
  color: #fefefe;
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

button:hover {
  background-color: #4A4E69;
  color: #fefefe;
}

input.error {
  border: 1px solid #EADAE4;
}

.error {
  color:#db2269;
  font-size: 0.625em;
  display: relative;
}

.first_name,
.last_name,
.email,
.phone_number,
.number_masks,
.type_masks,
.preferred_exchange,
.additional_information,
.password,
.confirm_password,
.account,
.location {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.submit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.left-column,
.right-column {
  flex: 50%;
  padding: 0 10px;
}

/* .left-column {
  position: relative; 
  bottom: 123px;
} */

.red {
  color: red;
}




/* .previewComponent {
  position: absolute; 
  bottom: 30px;
} */

.label {
  cursor: pointer;
}

#fileInput
{   
  /* padding: 10px;
  margin: 15px; */
  cursor: pointer;
  opacity: 0;
  position: absolute; 
  z-index: -1;
}

.imgPreview {
  text-align: center;
  /* margin: 5px 15px; */
  height: 100px;
  width: 100px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-radius: 50%;
}

.profile_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.previewText {
  width: 100%;
  margin-top: 30px;
}

/* .submitButton {
  background: black;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
} */


/* .submitButton:hover {
    background: #efefef
} */

/* .centerText {
  text-align: center;
  width: 500px;
} */

